.leftSideBar
  width: 300px
  background: #FBFCFF
  height: 100vh
  position: fixed
  transform: translateX(0%)
  .LHSLogo
    width: 185px
    height: 40px
    margin: 55px 0 45px 58px
    cursor: pointer
  .navItem
    display: flex
    align-items: center
    padding: 0 0 0 58px
    margin-bottom: 10px
    height: 50px
    cursor: pointer
    svg
      margin-right: 11px
      fill: #717394
      width: 20px
      height: 20px
    .navLabel
      +font(13px, 17px, #3E3E4F)

    &.active,
    &:hover
      background: #F1F5FD
      svg
        fill: $link
      .navLabel
        color: $link
        font-weight: 700
  .logoutLink
    position: absolute
    bottom: 20px
    width: 100%
  @media only screen and (max-width: 600px)
    transform: translateX(-100%)
    transition: 0.5s
    z-index: 20

.menuBtnIcon
  padding: 2px 4px
  border: 1px solid black
  display: none
  border-radius: 6px
  margin: 0px 4px
  @media only screen and (max-width: 600px)
    display: block

.menuBtnIconClose
  position: absolute
  display: none
  right: 6px
  top: 6px
  @media only screen and (max-width: 600px)
    display: block