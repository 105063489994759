.accordion
    margin: 2rem auto

.accordion-item
    margin-bottom: 1rem
    background-color: #ffffff

.accordion-title
    display: flex
    flex-direction: row
    justify-content: space-between
    cursor: pointer
    color: #5aadde
    padding: 2rem   
    line-height: 28px

.accordion-content
    padding: 1rem
    line-height: 32px
    color: #3a3838a8
    border-top: 1px solid #dbd8d886

.faqspage
    font-size: 18px


