.SupportFilePopup
    padding-top: 30px
    .SupportedTabs
        border-bottom: 1px solid #E7EFF5
        display: flex
        padding: 0 20px
        li.tabItem
            font-size: 16px
            line-height: 21px
            color: #3E3E4F
            padding: 0 0 15px 0
            margin-right: 50px
            cursor: pointer
            position: relative
            &.active
                font-weight: bold
                &::after
                    display: block
                    height: 3px
                    background: $link
                    width: 100%
                    position: absolute
                    content: ''
                    bottom: 0
    .supportFileTabContent
        padding-top: 25px
.languageTab
    ul
        display: flex
        flex-wrap: wrap
        li
            display: flex
            align-items: center
            border-right: 1px solid #E7EFF5
            width: 25%
            padding: 0 0 15px 20px
            span
                display: block
            .circleArrowRightIcon
                margin: 0 5px
            &:nth-child(4n)
                border: none
.fileFormatTab
    padding: 20px
    .fileFormatList
        display: flex
        .fileFormatList
            display: flex
            align-items: center
            justify-content: center
            width: 60px
            height: 33px
            border-radius: 3px
            border: 1px solid #D1D6E8
            color: #515266
            font-weight: 600
            font-size: 13px
            line-height: 26px
            margin: 0 26px 20px 0
