@import '_font'
@import '_mixins'
@import '_variables'

table
    width: 100%
    border-collapse: collapse
    +font(12px, 16px, #515266)
    margin-top: 20px
    thead
        tr
            background: linear-gradient(90.03deg, #707E9B -17.19%, #495878 111.86%)
            height: 40px
            th
                text-align: left
                color: $white
                +font(13px, normal, $white)
                font-weight: 600
                > span
                    vertical-align: middle
                    display: inline-block
                &:first-child
                    border-radius: 3px 0 0 3px
                    padding-left: 20px
                &:last-child
                    border-radius: 0 3px 3px 0
                    padding-right: 20px
                svg
                    width: 10px
                    fill: $white
                    display: block
                    margin-left: 5px
                .sortIcon
                    svg
                        width: 16px
                        height: 16px
                .sortDown
                    svg
                        transform: rotate(180deg)
            
    tr
        border-bottom: 1px solid #EBF0FC
        min-height: 45px
        td
            min-width: 100px
            padding: 15px 0
            @media only screen and (max-width: 600px)
                padding: 8px 0
            &:first-child
                padding-left: 16px
            &:last-child
                padding-right: 16px
        th, td
            width: 1%
            padding: 0.5rem
    a, .link
        color: $link
        text-decoration: underline
        font-weight: 700

.addFilesBtn
    display: flex
    justify-content: flex-end
    margin-top: 10px
    
