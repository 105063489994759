.fullPagePopup
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    z-index: 99999
    .overlay
        background: $black
        opacity: 0.9
        left: 0
        right: 0
        top: 0
        bottom: 0
        position: fixed
    .popupInner
        background: $white
        border-radius: 10px
        padding: 20px
        position: relative
        width: 400px
        .popupClose
            position: absolute
            top: 10px
            right: 10px
            cursor: pointer
            svg
                width: 20px
                height: 20px
                fill: $black
        .oneLineMessage
            display: flex
            align-items: center
            justify-content: center
            min-height: 60px
            text-transform: capitalize
            +font(20px, normal, null, roboto)
            &.errorMsg
                color: #E04F5F
            &.successMsg
                color: $link
    .popupMessageWrap
        display: flex
        flex-direction: column
        align-items: center
    .popupMessage
        margin-top: 16px
        +font(26px, 30px, #3E3E4F, roboto)
        &.success
            color: $link
    .messageblock
        margin: 56px -20px 40px
        background: #FAFCFF
        border-top: 1px dashed #CDDAEE
        border-bottom: 1px dashed #CDDAEE
        +font(22px, 26px, #3E3E4F, roboto)
        text-align: center
        padding: 12px
    &.success
        .popupInner
            width: 640px
            padding-top: 43px
        .popupMessageWrap
            .messageIconWrap
                position: relative
                width: 167px
                height: 125px
                img:last-child
                    position: absolute
                    bottom: 0
                    left: 0
                    right: 0
                    margin: 0 auto
        .orderDetails
            justify-content: center
            align-items: center
            color: #3E3E4F
            font-size: 13px
            margin-bottom: 28px
            > div
                border-right: 2px solid #cccccc
                margin-right: 10px
                padding-right: 10px
                span
                    padding-right: 5px
                    text-transform: capitalize
                &:last-child
                    border: 0
                    margin: 0
                    padding: 0
        .downloadRecipt
            +font(12px, 16px, #22A4EE)
            font-weight: 600
            text-decoration: underline
            align-items: flex-end
            text-transform: capitalize
            cursor: pointer
            margin-bottom: 33px
    &.Error
        .popupInner
            background: #FFF4F6
            width: 640px
            padding-top: 62px
        .messageblock
            border-color: #E04F5F
            color: #E04F5F
            background: #FFEFF2
            font-size: 15px
            line-height: 20px
            padding: 16px 0
        .tryAgain
            +font(13px, 17px, #3E3E4F)
            text-align: center
            margin-bottom: 35px
            a
                text-decoration: underline

#stfDialogPlace
    .Modal-module_modal__FqeFw.Modal-module_dialog__2UBIn
        width: 991px
        min-width: 201px
        max-width: 991px
        min-height: 100px
        max-height: 500px
        @media only screen and (max-width: 600px)
            width: 91%
        .stf__dialogClose svg
            fill: $white
            width: 18px
            height: 18px
        .Modal-module_modalTitle__1TtY9
            background: $link
            padding: 17px 20px
            +font(18px, 21px, $white, roboto)
        .tableWrap
            margin: 0
            .paginationWrap
                position: fixed
                bottom: 0
                width: 100%
                left: 0
                right: 0
                height: 60px
                background: #FAFCFF
                border-top: 1px solid #DCE0EA
                padding: 0 20px
                .showpages
                    background-color: $white
