.feedback-cont
    display: flex,
    justify-content: center,
    flex-direction: column
    margin: 2rem,
    padding: 4rem
    @media only screen and (max-width: 600px)
        padding: 1rem
        margin: 1rem
    flex: 1
    p
        font-size: 1.2rem
        margin-bottom: 1rem
        text-align: center
    .innerForm
        display: grid
        grid-template-columns: 2fr 2fr
        @media only screen and (max-width: 600px)
            grid-template-columns: 1fr
        gap: 2rem
        margin: 1rem 0rem
        input
            font-size: 1rem
            padding:12px 12px
    #message
        font-size: 1rem
        padding:12px 12px
        
    .btnDiv
        display: flex
        justify-content: flex-end
        margin: 1rem 0rem
        width: 100% 
        button
            background-color: #34C6C3
            border: none
            border-radius: 6px
            color: white
            font-size: 1.5rem
            padding: 1rem 2rem
            cursor: pointer

