.addProjectDetails
  margin-top: 22px
  .createOption
    justify-content: space-between
    padding-bottom: 20px
    @media only screen and (max-width: 600px)
      flex-direction: column
      gap: 8px

    .inputWrap
      width: 32%
      @media only screen and (max-width: 600px)
        width: 100%
      
      .label
        padding-bottom: 9px
        display: block
      input, select
        width: 100%

.browseTranslationWrap
  display: flex
  align-items: center
  .dzu-dropzone
    flex: 1
    background: #596886 !important
    border: 1px solid #DCE0EA !important
    box-sizing: border-box
    border-radius: 3px
    height: 65vh
    position: relative
    margin-top: 20px
    padding: 20px
    .dzu-inputLabel
      +font(22px, 29px, #CDD8E9, $baseFont)
      font-weight: 300
      background: #FAFCFF
      @media only screen and (max-width: 600px)
        padding: 0px 12px
        text-align: center
    .whiteBtn
      position: absolute
      right: 20px
      top: calc(50% - 20px)
    .dzu-submitButtonContainer
      margin: 0 0 -20px 0
      width: calc(100% + 40px)
      min-height: 111px
      height: 111px
      background: #4E5E7C
      display: flex
      align-items: center
      justify-content: center
      .dzu-submitButton
        background: $link !important
        font-family: $baseFont !important
    .dzu-previewContainer
      padding: 20px 0
      border-bottom: 1px solid #697897
      .dzu-previewStatusContainer .dzu-previewButton
        background: url(../images/crossWhite.svg) no-repeat 0 center !important
        width: 18px
        height: 18px
      .dzu-previewFileName
        padding: 8px 0 7px 40px
        color: $white
        background: url(../images/docs.svg) no-repeat 0 center
        +font(13px, 18px, $white, $baseFont)

.browseFileWrap
  display: flex
  align-items: center
  .dzu-dropzone
    flex: 1
    background: #596886 !important
    border: 1px solid #DCE0EA !important
    box-sizing: border-box
    border-radius: 3px
    height: 65vh
    position: relative
    margin-top: 20px
    padding: 20px
    .dzu-inputLabel
      +font(22px, 29px, #CDD8E9, $baseFont)
      font-weight: 300
      background: #FAFCFF
      @media only screen and (max-width: 600px)
        padding: 0px 12px
        text-align: center
    .whiteBtn
      position: absolute
      right: 20px
      top: calc(50% - 20px)
    .dzu-submitButtonContainer
      margin: 0 0 -20px 0
      width: calc(100% + 40px)
      min-height: 111px
      height: 111px
      background: #4E5E7C
      display: flex
      align-items: center
      justify-content: center
      .dzu-submitButton
        background: $link !important
        font-family: $baseFont !important
    .dzu-previewContainer
      padding: 20px 0
      border-bottom: 1px solid #697897
      .dzu-previewStatusContainer .dzu-previewButton
        background: url(../images/crossWhite.svg) no-repeat 0 center !important
        width: 18px
        height: 18px
      .dzu-previewFileName
        padding: 8px 0 7px 40px
        color: $white
        background: url(../images/file.svg) no-repeat 0 center
        +font(13px, 18px, $white, $baseFont)

.headerRightWrap
  align-items: center

.fileFormatLink
  +font(14px, 19px, $link)
  text-decoration: underline
  cursor: pointer
  margin-right: 22px

.txtImgOptions
  align-items: center
  margin-right: 42px
  width: 355px
  .radioWrap
    margin-right: 23px
    display: flex
    align-items: center
    cursor: pointer
    .inputWrap
      &.selected
        svg.radioIcon
          display: none
        svg.radioSelectedIcon
          display: block
      svg.radioIcon
        display: block
      svg.radioSelectedIcon
        display: none
      input[type="radio"]
        width: 22px
        height: 22px
        opacity: 0.7
        position: absolute
        z-index: 1
    label
      +font(14px, 19px,#3E3E4F)
      margin-left: 30px
      text-transform: capitalize
