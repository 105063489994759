.quotationTable
  width: calc(100% - 40px)
  margin: 20px 20px 45px
  .totleRow
    background: #fff
    border-top: 1px solid #ccc
    .quateLabel
      color: #566483
      font-weight: 700
      font-size: 18px
    .quateValue
      +font(18px, 24px, $link)
      font-weight: 700
