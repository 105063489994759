.userSection
  background: $white
  height: 80px
  border-radius: 8px
  display: flex
  align-items: center
  justify-content: space-between
  padding: 0 30px
  margin-bottom: 30px
  @media only screen and (max-width: 600px)
    padding: 0 12px
  .welcomeUser
    +font(18px, 21px, #717394, roboto)
    text-transform: capitalize
    display: flex
    justify-content: space-between
    flex: 1
    align-items: center
    padding-right: 70px
  .userInfoWrap
    display: flex
    align-items: center
    position: relative
    padding-bottom: 5px
    .userAvatar
      width: 41px
      height: 41px
      background: #ccc
      border-radius: 50%
      margin-right: 10px
    .userName
      +font(13px, 15px, #717394, roboto)
      display: flex
      align-items: center
      text-transform: capitalize
      cursor: pointer
      height: 41px
      .twoWayArrow
        border-color: $link
        margin-left: 20px
    .profileOptions
      position: absolute
      background: $link
      box-shadow: 10px 10px 30px rgba(43, 68, 120, 0.2)
      border-radius: 6px
      width: 157px
      top: 45px
      right: -30px
      @media only screen and (max-width: 600px)
        right: -12px
      z-index: 99
      display: none
      li
        padding: 16px 15px
        +font(12px, 14px, $white, roboto)
        display: flex
        align-items: center
        cursor: pointer
        span
          text-transform: capitalize
        &.profileItem,
        &.changePwdItem,
        &.signOutItem
          img, svg
            width: 20px
            height: 20px
            margin-right: 9px
        &.signOutItem svg
          fill: $white
        &:first-child
          border-radius: 6px 6px 0 0
        &:last-child
          border-radius: 0 0 6px 6px
        &:hover
          background: #24B5B2
    &:hover
      .profileOptions
        display: block

.projectListWrap
  .projectList
    gap: 16px
    @media only screen and (max-width: 600px)
      flex-direction: column

    .projectBlock
      width: 24%
      height: 100px
      padding: 12px 0 17px 20px
      color: $white
      border-radius: 6px
      overflow: hidden
      position: relative
      &::after
        content: "COMING SOON"
        position: absolute
        background: rgba(255, 255, 255, 0.8)
        color: #62718f
        top: 0
        left: 0
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        height: 100%
        font-size: 21px
        font-weight: bold
        font-family: 'roboto', sans-serif
        z-index: 9
      .circle
        width: 165px
        height: 174px
        position: absolute
        border-radius: 50%
        right: -74px
        top: -70px
      h2
        +font(18px, 24px)
        text-transform: uppercase
        z-index: 1
        position: relative
        letter-spacing: 1px
      h5
        +font(13px, 17px)
        font-weight: 400
        z-index: 1
        position: relative
      .twoOptions
        display: flex
        flex-direction: row
        > h5
          &:first-child
            border-right: 1px solid #FFFFFF
            margin-right: 10px
            padding-right: 10px
      .addProject
        align-items: center
        margin-top: 15px
        +font(12px, 16px, $white)
        font-weight: bold
        text-transform: uppercase
        cursor: pointer
        position: relative
        z-index: 1
        svg
          width: 15px
          height: 15px
          margin-right: 6px
      &.transcriptionBlock
        background: linear-gradient(90deg, #EE6054 -2.35%, #FFBAB5 99.59%), #FFFFFF
        box-shadow: 2px 15px 50px rgba(238, 96, 84, 0.25)
        cursor: pointer
        .circle
          background: #FB877D
        &::after
          content: none
      &.translationBlock
        background: linear-gradient(90deg, #34C6C3 -2.35%, #72FCF9 99.59%), rgba(255, 255, 255, 0.9)
        box-shadow: 2px 15px 50px rgba(52, 198, 195, 0.25)
        cursor: pointer
        .circle
          background: #57E3E0
        &::after
          content: none
      &.subtitlingBlock
        background: linear-gradient(90deg, #22A4EE -2.35%, #7ED0FF 99.59%), rgba(255, 255, 255, 0.9)
        box-shadow: 2px 15px 50px rgba(34, 164, 238, 0.25)
        cursor: pointer
        .circle
          background: #44B7FA
        &::after
          content: none
      &.allProjectBlock
        background: linear-gradient(90deg, #8CD06B -2.35%, #B0F68D 99.59%), rgba(255, 255, 255, 0.9)
        box-shadow: 2px 15px 50px rgba(120, 207, 77, 0.25)
        cursor: pointer
        .circle
          background: #99DF7B
      @media only screen and (max-width: 600px)
        width: 100%
  .projectTableWrap
    margin-top: 49px
  header
    h2
      span
        font-weight: 400
        color: #ccc
    .projectSearch
      input
        border: 1px solid #DCE0EA
        &:placeholder
          +font(13px, 17px, #515266)
