body
  +font(14px, 16px, #000, $baseFont)
  height: 100%
*
  margin: 0
  padding: 0
  box-sizing: border-box
  outline: none

div[id=root], html
  height: 100%

.App 
  height: 100%

a
  cursor: pointer
  text-decoration: none
  color: $link
  &:hover,
  &:focus
    text-decoration: none
    color: $link
    outline: none
button[disabled],
html input[disabled]
  cursor: default
  color: #CC9999
li
  list-style-type: none
.clearfix::after
  content: ""
  clear: both
  display: table

.form-control
  height: 40px
  +font(16px, 18px, #aaa)
  border: 1px solid #eee
  box-shadow: none

select::-ms-expand
  display: none
.flex-column
  flex-direction: column

.cursorPointer
  cursor: pointer
.hide
  display: none !important
.show
  display: block
svg
  opacity: 1

.relative
  position: relative
.d-flex
  display: flex
.flex-col
  flex-direction: row
  @media only screen and (max-width: 600px)
    gap: 1rem
    flex-direction: column
.flex-wrap
  flex-wrap: wrap
.align-center
  align-items: center
.justify-between
  justify-content: space-between
.justify-center
  justify-content: center !important
.center
  text-align: center
.container
  width: 100%

.twoWayArrow
  width: 8px
  height: 8px
  border-right: 1px solid #000
  border-bottom: 1px solid #000
  transform: rotate(45deg)

// SEARCH
.search
  position: relative
  input
    width: 335px
    @media only screen and (max-width: 600px)
      width: 200px
    height: 40px
    border: 1px solid #D0D5DF
    background: $white
    border-radius: 6px
    padding: 0 14px 0 17px
    &:placeholder
      +font(13px, 17px, #515266)
    &:focus, .updated
      border: 1px solid $link !important
      color: $link
      font-weight: 600
  .searchBtn
    background: none
    border: 0
    position: absolute
    height: 40px
    width: 40px
    right: 0
    border-radius: 0 6px 6px 0
    cursor: pointer
    svg
      fill: #566584
      width: 16px
      height: 16px

// TABLE
.tableWrap
  background: #FFFFFF
  box-shadow: 4px 15px 20px #E8EFFC
  border-radius: 6px
  padding: 20px
  margin-top: 24px
  header
    align-items: center
    justify-content: space-between

.block
  background: $white
  box-shadow: 4px 15px 20px #E8EFFC
  border-radius: 6px
  padding: 24px 20px
  h2
    +font(16px, 21px, #3E3E4F)
    text-transform: capitalize

// BUTTON
.dzu-submitButton,
.btn
  width: 190px
  height: 50px
  background: $link
  box-shadow: 4px 10px 15px rgba(52, 198, 195, 0.2)
  border-radius: 6px
  +font(14px, 19px, $white)
  font-weight: bold
  text-transform: uppercase
  display: flex
  align-items: center
  justify-content: center
  border: 0
  cursor: pointer

.whiteBtn
  background: $white
  border: 1px solid #D8DBE2
  border-radius: 6px
  width: 90px
  height: 40px
  +font(12px, 16px, #526180)
  cursor: pointer

.downloadBtn
  width: 24px
  height: 24px
  background: $white
  border-radius: 3px
  border: 1px solid $link
  cursor: pointer
  .downloadArrowIcon
    fill: $link
  &:hover, &.active
    background: $link
    .downloadArrowIcon
      fill: $white
.deleteBtn
  width: 16px
  height: 16px
  border: none
  background: none
  cursor: pointer
.invoiceBtn
  width: 74px
  height: 24px
  border: 1px solid $link
  display: flex
  align-items: center
  justify-content: center
  border-radius: 3px
  background: $white
  +font(12px, normal, $link)
  cursor: pointer
  &:hover, &.active
    background: $link
    color: $white
.quoteBtn
  background: $white
  border: 1px solid #4E5D7D
  border-radius: 4px
  color: #4E5D7D
  height: 40px
  display: flex
  align-items: center
  justify-content: center
  text-transform: uppercase
  font-weight: bold
  font-size: 14px
  cursor: pointer
  &:hover
    background: #4E5D7D
    color: $white

// INPUT
.inputBlock
  display: flex
  flex-direction: column
  .label
    margin-bottom: 9px
    .requiredField
      color: #FF0000

.inputWrap
  input, textarea
    background: #FAFCFF
    border: 1px solid #DCE0EA
    border-radius: 4px
    height: 40px
    +font(13px, 17px, #000000)
    width: 100%
    padding: 0 10px
    &:focus, &.updated
      background: #FFFFFF
      border: 2px solid $link
      color: $link
      font-weight: 600
  &.error
    input
      background: #FFF3F5 !important
      border: 1px solid #E04F5F !important
      color: #E04F5F
  &.error
    textarea
      background: #FFF3F5 !important
      border: 1px solid #E04F5F !important
      color: #E04F5F
  textarea
    padding: 10px
    height: 80px
    width: 100%
    resize: none
.inputErrorMsg
  +font(11px, 15px, #E04F5F)
  text-transform: capitalize

// credit card Input
.StripeElement
  background: #fafcff
  border: 1px solid #dce0ea
  border-radius: 4px
  height: 40px
  font-size: 13px
  line-height: 17px
  color: #3e3e4f
  width: 100%
  padding: 7px 10px 0
  &--focus
    background: #ffffff
    border: 2px solid #34c6c3
    color: #34c6c3
    font-weight: 600

select
  border: 1px solid #DCE0EA
  box-sizing: border-box
  border-radius: 4px
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
  background: url(../images/selectArrow-twoway.svg) no-repeat 95% center #FAFCFF
  cursor: pointer
  width: 190px
  height: 40px
  padding: 0 30px 0 10px
  color: #000000
  font-weight: 400
  &:focus
    border-color: $link
    color: $link
    font-weight: 600
    option
      color: $black

.label
  +font(13px, 17px, #3E3E4F)
  margin-right: 11px
  text-transform: capitalize
  .requiredField
    +font(15px, null, #FF0000)

// MOBILE FIELD
.mobileField
  position: relative
  select
    position: absolute
    height: 38px
    width: 83px
    border-radius: 4px 0 0 4px
    padding: 0 0 0 12px
    border: 0
    left: 1px
    top: 1px
    border-right: 1px solid #ddd
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    background: url(../images/selectArrow.png) no-repeat 88% center
    cursor: pointer
  input
    padding-left: 93px !important

// full Page Popup

.contentLeft
  flex: 1

.contentRight
  width: 270px
  margin-left: 30px
  @media only screen and (max-width: 600px)
    width: 100%
    margin-left: 0px


.blockFooter
  display: flex
  align-items: center
  justify-content: center

.pageRight
  flex: 1
  padding: 30px
  overflow-x: hidden
  @media only screen and (max-width: 600px)
    padding: 10px
  background: #F1F5FD
  margin-left: 300px
  min-height: 100vh
  @media only screen and (max-width: 600px)
    margin-left: 0px

.pageRight > form
  flex: 1
  @media only screen and (max-width: 600px)
    flex-direction: column

.orderPlace
  select
    margin-bottom: 30px
  .totleRow
    padding: 21px 0 24px
    border-width: 1px 0 1px 0
    border-color: #EEF1F6
    border-style: solid
    //display: flex
    justify-content: space-between
    align-items: center
    +font(14px, 16px, #717394, roboto)
  .paymentCardRow
    padding: 21px 0 24px
    border-width: 1px 0 1px 0
    border-color: #EEF1F6
    border-style: solid
    display: block
    justify-content: space-between
    align-items: center
    +font(14px, 16px, #717394, roboto)

.fullPageLoader
  .overlay
    background: rgba(0, 0, 0, 0.4)
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
  .loaderInner
    background: $white
    border-radius: 4px
    min-width: 300px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    padding: 20px
    .loadingTxt
      margin-top: 28px
    .messageTxt
      margin-top: 20px
    .loadingTxt,
    .messageTxt
      +font(16px, 22px, #B0B0B0, $baseFont)
      text-align: center
.tableWrap
  .projectTable
    overflow-x: auto
    table
      margin-bottom: 36px
      overflow-x: auto

.css-yk16xz-control,
.css-1pahdxg-control
  height: 40px
  > *
    height: 40px
.targetLangSelect
  width: 100% !important
  .css-yk16xz-control,
  .css-1pahdxg-control
    min-height: 40px
    height: auto
    > *
      min-height: 40px
      height: auto
.css-1rhbuit-multiValue
  background: $link !important
  border-radius: 5px !important
  > div
    color: $white
.css-b8ldur-Input
  margin: 0 !important
  padding: 0 !important
.instructionarea
  width: 100% !important
.addProjectBtn
  justify-content: flex-end
  padding-right: 23px
  .btn
    width: auto
    padding: 0 15px
    height: 35px
    font-size: 12px
.recordingPage
  .tableWrap.projectTableWrap
    margin-top: 15px

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear
    display: none

button
  cursor: pointer
button:hover
  opacity: 0.7

button:disabled
  opacity: 0.7
  cursor: default !important