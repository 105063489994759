.payment
  h2
    +font(18px, 21px, #717394, roboto)
    padding: 2px 0 24px 0
    font-weight: 500
  .paymentBlock
    margin-bottom: 40px
    header
      height: 40px
      background: linear-gradient(90.03deg, #707E9B -17.76%, #495878 115.6%)
      border-radius: 3px
      display: flex
      align-items: center
      justify-content: space-between
      padding: 0 12px 0 20px
      .paymentTitle
        +font(14px, 16px, $white, roboto)
      .editLink
        display: flex
        align-items: center
        +font(12px, 14px, $white, roboto)
        cursor: pointer
        span
          text-decoration: underline
        svg
          width: 16px
          height: 16px
          fill: $white
          margin-right: 5px

    .billingAddressContent
      padding-top: 20px
      .fullNameField
        flex-wrap: wrap
        .inputBlock
          width: 31.3%
          margin-right: 2%
          @media only screen and (max-width: 600px)
            width: 100%
            margin-right: 0
          .inputWrap
            width: 100%
      .inputBlock
        margin-bottom: 20px
        .label
          min-width: 62px
        .inputWrap,
        .selectWrap > select
          width: 192px
          @media only screen and (max-width: 600px)
            width: 100%
      .addressField .inputWrap
        width: 100%
      .country-state 
        @media only screen and (max-width: 600px)
          flex-direction: column
    .orderDetails
      padding: 18px 20px 14px
      background: #FAFCFF
      .orderBlock
        flex: 1
        > div
          line-height: 30px
        span
          +font(13px, 15px, #3E3E4F, roboto)
          &.value
            color: $link
        &:last-child
          border-left: 1px solid #DCE0EA
          padding-left: 19px

.orderPlace
  h2
    border-bottom: 1px solid #EEF1F6
    padding: 0 0 22px 0
    margin: 0
  .order-projectDetails
    margin-bottom: 15px
    li
      display: flex
      flex-direction: column
      border-bottom: 1px solid #EEF1F6
      padding: 15px 0
      +font(13px, normal)
      &:last-child
        border: 0
      .value
        font-weight: 600
      &.twoOptions
        display: flex
        flex-direction: row
        > div
          &:first-child
            border-right: 1px solid #EEF1F6
            margin-right: 15px
            width: 50%
          span
            display: block
.expiryCVV
  display: flex
  justify-content: space-between
  flex-basis: 45%
  margin-top: 15px
  .inputBlock
     width: 45%
