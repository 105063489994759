.loginForm
  .loginTitle
    +font(18px, 24px, #000)
    font-weight: bold
    margin-bottom: 25px
  .loginType
    ul
      display: flex
      flex-wrap: wrap
      align-items: center
      justify-content: center
      li
        margin-bottom: 20px
        &:nth-child(odd)
          margin-right: 17px
    button
      +font(14px, 19px)
      font-weight: 600
      width: 213px
      height: 49px
      border: 0
      border-radius: 100px
      box-shadow: 4px 4px 15px #D6D9DF
      display: flex
      align-items: center
      padding: 0 23px
      cursor: pointer
    .FBBtn > button
      background: #3B5998
      color: #fff
      img
        margin-right: 20px
    .googleBtn > button
      background: #FFFFFF
      color: #333
      img
        margin-right: 13px
    .officeBtn > button
      background: #EB3C00
      color: #fff
      img
        margin-right: 16px
  .orDivider
    +font(14px, 19px, #bbb)
    padding: 0 13px
    position: relative
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 24px
    &::before,
    &::after
      content: ''
      position: absolute
      background: #eee
      height: 1px
      width: 45.2%
    &::after
      right: 0
    &::before
      left: 0
  .remember-forgotPass
    display: flex
    align-items: center
    justify-content: space-between
    margin: 20px 0 40px
    .rememberMeWrap
      display: flex
      align-items: center
      cursor: pointer
      input
        width: 15px
        height: 15px
        border: 1px solid #ccc
        margin-right: 10px
      label
        +font(12px, 16px, #999)
        font-weight: 600
        margin-top: -1px
    .Forgotpassword
      +font(12px, 16px, $link)
      text-decoration: underline
      cursor: pointer


