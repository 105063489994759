=font($size: null, $line-height: null, $color: null, $family: null, $weight: null)
  font-size: $size
  line-height: $line-height
  color: $color
  font-family: $family
  font-weight: $weight

=truncate($textOverflow: null, $overflow: null, $width: null, $whiteSpace: null)
  text-overflow: $textOverflow
  overflow: $overflow
  width: $width
  white-space: $whiteSpace

=imageWrapper($width: null, $height: null)
  .imageWrapper
    width: $width
    height: $height
    img
      width: 100%
      height: 100%

=position($position, $top: null, $right: null, $bottom: null, $left: null)
  position: $position
  top: $top
  right: $right
  bottom: $bottom
  left: $left

=borderRadius($border: null, $radius: null)
  border: $border
  border-radius: $radius

=flexAlign($justify-content: null, $align-items: null)
  display: flex
  justify-content: $justify-content
  align-items: $align-items

=border($border-width: null, $border-style: null, $border-color: null)
  border-width: $border-width
  border-style: $border-style
  border-color: $border-color

=widthHeight($width: null, $height: null)
  width: $width
  height: $height

=colorWithBackground($color: null, $background-color: null)
  color: $color
  background-color: $background-color

=svgWrap($width: null, $height: null)
  .svgWrap
    width: $width
    height: $height
    svg 
      width: 100%
      height: 100%
